const config = {
  apiGateway: {
    REGION: 'us-east-1',
    INTERNAL_API_URL: 'https://qifow5u8v5.execute-api.us-east-1.amazonaws.com/prod',
    COMPANY_API_URL: 'https://dd5jn2c6z2.execute-api.us-east-1.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'taxonomy-company-1.11.1',
    HOSTNAME: 'https://company.rossing.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.rossing.forwoodsafety.com',
    WEBSOCKET: 'wss://407rcjdf6d.execute-api.us-east-1.amazonaws.com/prod'
  },
};

export default config;